html {
  background: #fafbfc;
  font-family: "PT Root";
}
body {
  margin: 0;
  font-family: "PT Root";
  /* font-family: "PT Root", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

.cell {
  border: none;
}
